import * as React from "react";
import InlineSVG from "react-inlinesvg";
import styled from "styled-components";
import {
  toREM,
  toVWDesktop,
  toVWMobile,
  vwDesktop,
  vwMobile,
} from "../../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../../helpers/theme";
import linkArrow from "../../RecipeSearch/assets/linkArrow.svg";
import Link from "../Link";

export type BackButtonProps = {
  label: string;
  link: string;
  className?: string;
};

export const BackButton = ({ label, link, className }: BackButtonProps) => {
  return (
    <Back className={className} to={link} color={"#75C154"}>
      <Arrow src={linkArrow} color={"#75C154"} />
      <span>{label}</span>
    </Back>
  );
};

const Arrow = styled(InlineSVG)<{ color?: string }>`
  margin-right: ${toVWMobile(8)}vw;
  width: ${toVWMobile(20)}vw;
  height: ${toVWMobile(11)}vw;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-right: ${toVWDesktop(8)}vw;
    width: ${toVWDesktop(20)}vw;
    height: ${toVWDesktop(11)}vw;
  }

  & path {
    fill: ${(props) => props.color};
  }
`;
const Back = styled(Link)<{ color: string }>`
  direction: ltr;
  display: block;
  width: fit-content;
  margin-bottom: ${vwMobile(20)};
  margin-right: auto;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  font-weight: 700;
  letter-spacing: ${toREM(0.16)}rem;
  color: ${(props) => (props.color ? props.color : "#033305")};
  text-align: left;
  transition: all 0.2s;
  text-decoration: none;

  &:hover {
    cursor: pointer;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      border-bottom: ${toVWDesktop(4)}vw solid currentColor;
    }
  }

  ${MEDIA_DESKTOP} {
    height: ${vwDesktop(24)};
    margin-bottom: ${vwMobile(16)};
  }
`;

export default BackButton;
