import React, { FC } from "react";
import styled from "styled-components";

import {
  toVWMobile,
  toVWDesktop,
  toREM,
  vwDesktop,
  vwMobile,
} from "../../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../../helpers/theme";
import icon from "./caret.svg";

export type SelectProps = {
  options: OptionsType[];
  label: string;
  onChange: (selected: OptionsType, index: number) => void;
  className?: string;
  selected?: number;
} & React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

type OptionsType = {
  label: string;
  value: string;
};

const Select: FC<SelectProps> = ({
  options = [],
  onChange,
  className,
  label,
  selected,
  ...rest
}: SelectProps) => {
  const handleChange = (e) => {
    const currentTag = options?.find(
      (option) => option?.value === e.target.value
    );

    const currentTagIndex = options?.indexOf(currentTag);
    onChange(currentTag, currentTagIndex);
  };

  return (
    <Container className={className}>
      <img className={`icon`} src={icon} alt="arrow down icon" />
      <SelectMain onChange={handleChange} aria-label={label} {...rest}>
        {options?.map((option, index) => (
          <option
            key={index}
            value={option.value}
            selected={selected === index}
          >
            {option?.label}
          </option>
        ))}
      </SelectMain>
    </Container>
  );
};

const SelectMain = styled.select`
  cursor: pointer;
  width: 100%;
  color: #75c154;
  background-color: transparent;
  border: none;
  border-bottom: 4px solid #75c154;
  display: flex;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  letter-spacing: 0.06em;
  padding: ${toVWMobile(5)}vw ${toVWMobile(50)}vw ${toVWMobile(5)}vw 0;
  height: ${toVWMobile(44)}vw;
  transition: border 0.2s;
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    outline: ${vwMobile(3)} solid #75c154;
    ${MEDIA_DESKTOP} {
      outline: ${vwDesktop(3)} solid #75c154;
    }
  }

  option {
    font-weight: 800 !important;
    letter-spacing: 0.06em;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(44)}vw;
    padding: ${toVWDesktop(5)}vw ${toVWDesktop(50)}vw ${toVWDesktop(5)}vw
      ${toVWDesktop(10)}vw;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .icon {
    cursor: pointer;
    position: absolute;
    right: 5%;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    width: ${vwMobile(13)};
    height: ${vwMobile(7)};
    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(13)};
      height: ${vwDesktop(7)};
    }
  }
`;

export default Select;
