import { graphql } from "gatsby";
import { useIntl } from "../../hooks/useIntl";
import React from "react";
import { useCallback } from "react";
import Page from "../../components/Page";
import { getTranslations } from "../../helpers/translations";
import { getAssetURL } from "../../helpers/assets";
import SEO from "../../components/SEO";
import { parseSEO } from "../../helpers/seo";
import NewsroomAssetsList, {
  NewsroomAssetsListProps,
} from "../../components/NewsroomAssetsList";
import { isPublished } from "../../helpers/directus";
import { useStaticLabels } from "../../hooks/useStaticLabels";

export default function FactSheets({ data }: { data: any }) {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.fact_sheets_page.translations",
      intl.locale
    );
  }, [data]);

  const getAssets = useCallback((): NewsroomAssetsListProps => {
    const t = getRootTranslatedData();

    return {
      title: t?.asset_type_label,
      labels: {
        back: labels?.backToNewsroomLabel,
        download: labels?.download,
      },
      tabs: t?.tabs_list
        ?.map(({ asset_tab_id }: { asset_tab_id: any }) => {
          const { status, tab_title, assets_list } =
            getTranslations(asset_tab_id, "translations", intl.locale) ?? {};

          return {
            status,
            title: tab_title,
            assets: assets_list
              ?.map(({ asset_files_id }: { asset_files_id: any }) => {
                const translatedAsset =
                  getTranslations(
                    asset_files_id,
                    "translations",
                    intl.locale
                  ) ?? {};

                return {
                  preview: {
                    src: getAssetURL(translatedAsset?.image),
                    alt: translatedAsset?.image_alt,
                  },
                  status: translatedAsset?.status,
                  title: translatedAsset?.name,
                  file: getAssetURL(translatedAsset?.file),
                };
              })
              ?.filter(({ status }: { status: string }) => isPublished(status)),
          };
        })
        ?.filter(({ status }: { status: string }) => isPublished(status)),
    };
  }, [getRootTranslatedData, intl]);

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={<SEO {...parseSEO(getRootTranslatedData(), intl?.locale)} />}
    >
      <NewsroomAssetsList {...getAssets()} />
    </Page>
  );
}

export const query = graphql`
  query ($locale: String) {
    directus {
      fact_sheets_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          languages_code {
            code
            name
          }
          status
          asset_type_label
          tabs_list {
            asset_tab_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                status
                tab_title
                assets_list {
                  asset_files_id {
                    translations(
                      filter: { languages_code: { code: { _eq: $locale } } }
                    ) {
                      languages_code {
                        code
                        name
                      }
                      status
                      name
                      file {
                        id
                        filename_disk
                      }
                      image_alt
                      image {
                        id
                        filename_disk
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
