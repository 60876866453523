import React, { FC, useState } from "react";
import styled from "styled-components";

import { vwMobile, vwDesktop, rem } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { ROUTES } from "../../helpers/routes";
import Link from "../shared/Link";
import { Image } from "../../types";
import { ButtonLink } from "../shared/ButtonLink";
import SelectMain from "../shared/Select";
import { BackButton } from "../shared/BackButton";

export type NewsroomAssetsListProps = {
  hideTitle?: boolean;
  title: string;
  labels: {
    back: string;
    download: string;
    preview?: string;
  };
  tabs: Array<{
    title: string;
    assets: Array<{
      preview?: Image;
      title: string;
      file: string;
    }>;
  }>;
};

const NewsroomAssetsList: FC<NewsroomAssetsListProps> = ({
  hideTitle,
  title,
  labels,
  tabs = [],
}: NewsroomAssetsListProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <Container>
      <Tabs>
        <BackButton label={labels?.back} link={ROUTES.NEWSROOM} />
        <Mobile>
          <Header1>{title}</Header1>
        </Mobile>
        <Mobile>
          <SelectMain
            options={tabs?.map((t, i) => ({ label: t?.title, value: `${i}` }))}
            onChange={(_, index) => {
              setSelectedTab(index);
            }}
          />
        </Mobile>
        <TabsList>
          {tabs?.map((t, i) => (
            <TabOption
              key={i}
              onClick={() => setSelectedTab(i)}
              selected={i === selectedTab}
            >
              {t.title}
            </TabOption>
          ))}
        </TabsList>
      </Tabs>
      <Content>
        <Desktop>
          <Header1>{title}</Header1>
        </Desktop>
        {tabs[selectedTab]?.title !== title && (
          <Desktop>
            <Header2>{tabs[selectedTab]?.title}</Header2>
          </Desktop>
        )}
        <AssetList>
          {tabs[selectedTab]?.assets?.map((asset, i) => (
            <Asset key={i}>
              <Flex>
                {asset?.preview?.src && (
                  <AssetPreview
                    alt={asset?.preview?.alt}
                    src={asset?.preview?.src}
                  />
                )}
                <AssetTitle>{asset?.title}</AssetTitle>
              </Flex>
              <Actions>
                <ButtonLink
                  downlaod
                  blank
                  link={asset?.file}
                  label={labels?.download}
                />
              </Actions>
            </Asset>
          ))}
        </AssetList>
      </Content>
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${MEDIA_DESKTOP} {
    flex-direction: row;
  }
`;

const Tabs = styled.div`
  width: 100%;
  background: #033305;
  display: flex;
  flex-direction: column;
  padding: ${vwMobile(16)};
  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(40)};
    padding: ${vwDesktop(40)} ${vwDesktop(64)} ${vwDesktop(80)};
    width: ${vwDesktop(375)};
  }
`;

const Mobile = styled.div`
  display: contents;
  ${MEDIA_DESKTOP} {
    display: none;
  }
`;

const Desktop = styled.div`
  display: none;
  ${MEDIA_DESKTOP} {
    display: contents;
  }
`;

const TabsList = styled.div`
  display: none;
  ${MEDIA_DESKTOP} {
    display: flex;
    flex-direction: column;
    gap: ${vwDesktop(20)};
  }
`;

const Content = styled.div`
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: ${vwMobile(16)};
  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(1065)};
    padding: ${vwDesktop(40)} ${vwDesktop(205)} ${vwDesktop(40)}
      ${vwDesktop(40)};
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${vwMobile(20)};
  ${MEDIA_DESKTOP} {
    flex-direction: row;
    gap: ${vwDesktop(20)};
  }
`;

const AssetList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(16)};
  ${MEDIA_DESKTOP} {
    padding-top: ${vwDesktop(40)};
    gap: ${vwDesktop(16)};
  }
`;

const Asset = styled.div`
  width: 100%;
  padding: ${vwMobile(16)};
  border: 2px solid #033305;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${vwMobile(20)};
  ${MEDIA_DESKTOP} {
    flex-direction: row;
    align-items: center;
    padding: ${vwDesktop(16)};
    min-height: ${vwDesktop(134)};
    gap: ${vwDesktop(89)};
  }
`;

const Actions = styled.div``;

const AssetPreview = styled.img`
  width: ${vwMobile(299)};
  height: ${vwMobile(299)};
  object-fit: contain;
  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(102)};
    height: ${vwDesktop(102)};
  }
`;

const AssetTitle = styled.p`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(24)};
  line-height: ${rem(31)};
  letter-spacing: 0;
  font-weight: 800;
  color: #033305;
`;

const Header1 = styled.h1`
  text-transform: uppercase;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(44)};
  line-height: ${rem(53)};
  letter-spacing: 0.03em;
  font-weight: 900;
  color: #ffffff;
  ${MEDIA_DESKTOP} {
    color: #033305;
    font-size: ${rem(48)};
    line-height: ${rem(56)};
  }
`;
const Header2 = styled.h2`
  text-transform: uppercase;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(44)};
  line-height: ${rem(53)};
  letter-spacing: 0.03em;
  font-weight: 900;
  color: #ffffff;
  ${MEDIA_DESKTOP} {
    color: #033305;
    font-size: ${rem(48)};
    line-height: ${rem(56)};
  }
`;

const BackLink = styled(Link)`
  color: #75c154;
  text-decoration: none;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(16)};
  line-height: ${rem(21)};
  letter-spacing: 0.01em;
  font-weight: 800;
  margin-bottom: ${vwMobile(40)};
  ${MEDIA_DESKTOP} {
    margin-bottom: 0;
    font-size: ${rem(16)};
    line-height: ${rem(21)};
    font-weight: 800;
  }
`;

const TabOption = styled.button<{ selected?: boolean }>`
  width: fit-content;
  border: none;
  background: transparent;
  color: ${(props) => (props.selected ? "#75C154" : "#ffffff")};
  text-decoration: none;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  border-bottom: ${(props) =>
    props.selected ? "3px solid #75C154" : "3px solid transparent"};
  font-size: ${rem(16)};
  line-height: ${rem(28)};
  letter-spacing: 0.06em;
  font-weight: 900;
  text-align: left;
  cursor: pointer;
  text-transform: uppercase;
  ${MEDIA_DESKTOP} {
    font-size: ${rem(16)};
    line-height: ${rem(21)};
    font-weight: 900;
  }
`;

const Example = styled.div`
  height: ${vwMobile(75)};
  width: ${vwMobile(72)};
  object-fit: contain;

  ${MEDIA_DESKTOP} {
    height: ${vwDesktop(75)};
    transform: scale(1);
    width: ${vwDesktop(72)};
  }
`;

export default NewsroomAssetsList;
